<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">HOW TO FINANCE A CAR</div>
            </div>
        </div>
        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h1>HOW TO FINANCE A CAR</h1>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Car finance entirely depends upon your financial situation and car specific budget. Multiple
                                income sources and a good balance of expenses provides you with the opportunity to spend big
                                on car financing. Online automotive websites are reporting that people are paying great heed
                                to online purchases of the car, which is good news for car financing as well. We can start
                                the financing strategy by considering the following aspects of car financing.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>SET A FINANCING STRATEGY AND KNOW THE OUTCOME</h3>
                            <p>Financing a car can be difficult without setting an organised strategy and thought process.
                                Weighing out your annual income and budget worksheet is a good initiative towards
                                orchestrating a successful scheme. Decide the category of the car which you want to like,
                                whether it be Sedan, SUV, hatchback or sports car, depending upon your financial
                                feasibility. This exercise will help you modify your budget needs and meet your expenses
                                accordingly. Being an optimist and realistic is a good sign of maturity; that is why knowing
                                the outcome of your actions is necessary. An ill-defined strategy can push you into a
                                pothole and a vicious cycle of unprecedented financial breakdown.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>PROCEED WITH THE ACTION PLAN</h3>
                            <p>Once you have a plan of your strategy, proceed with materializing your dream to have a car of
                                your own. Two mindsets coexist regarding this; either you can buy it or lease it. Talking on
                                the need basis, it's a reasonable investment to buy a new car and drive it for the next ten
                                years until you earn enough to shift on another car of your choice or be able to complete
                                your loan payment. On the other side, if you are a car freak and like to take on different
                                cars on a driveway, leasing is the viable option for you. Leasing a car for 3-4 years and
                                then switching it with a new one would allow you to live your dream of experiencing new cars
                                once in a while. Value depreciation with time, wear and tear of the leased and a purchased
                                car play a role in finalising its worth over the following years.</p>
                        </div>
                    </div>

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>PATIENCE IS A VIRTUE</h3>
                            <p>Every business model runs based on trust and patience. Starting a business with the sheer
                                expectation of earning a profit in no time should be tagged as ignorance. Apply the same
                                ideology to the automotive business; now you have the financial strategy, start looking out
                                for the potential vehicle you want to buy. Search the online websites, fill in the specific
                                details of the car you want, and negotiate for your price. If you are heading towards a
                                dealer, then let him know about your choice and wait for the right vehicle if you had to
                                because rushing might result in buying an inappropriate car. Online tools like Kelley blue
                                book are famous for determining a particular car's price.</p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>BEWARE OF THE ADDITIONAL COSTS</h3>
                            <p>As soon as you have finalised the deal, don't forget to consider that there would be
                                additional costs like yearlong loan payment and interest depending upon the terms and
                                regulations. To understand the full scenario, look for extended warranties and hidden
                                transportation charges for this you will need a <router-link to="/">professional
                                    help</router-link>. After covering all these aspects, now is the time to get in the
                                driving seat and cherish your dreams.</p>

                        </div>
                    </div>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1%;
    margin-bottom: 36px;
    width: 100%;
}

.ddRow h2,
.ddRow h1,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: block;
    }

    .ddCol-6 {
        width: 100%;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  